/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ACL from "./acl";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import localization from "./localization.js";

import "./assets/css/normalize.css"; // Normalize CSS
import "./assets/css/main.css"; // Main CSS
import "bootstrap"; // Bootstrap CSS
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/all.min.css"; // Fontawesome CSS
import "./assets/fonts/flaticon.css"; // Flaticon CS
import "./assets/css/animate.min.css"; // animated
// import "./assets/css/jquery.dataTables.min.css";
import "./assets/css/pretty-checkbox.css";
import PrettyCheck from "pretty-checkbox-vue/check";
Vue.component("p-check", PrettyCheck);
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/css/vuetify-light-theme.css";

import "./assets/css/style.css"; // custom style (main theme style)
import "./assets/jquery.calendars.package-2.1.1/css/humanity.calendars.picker.css";
import "./assets/jquery.calendars.package-2.1.1/js/jquery.calendars.js";
import "./assets/jquery.calendars.package-2.1.1/js/jquery.calendars.plus.js";
import "./assets/jquery.calendars.package-2.1.1/js/jquery.calendars.islamic.js";

import "./assets/jquery.calendars.package-2.1.1/js/jquery.plugin.js";
import "./assets/jquery.calendars.package-2.1.1/js/jquery.calendars.picker.js";
import "./assets/css/vuetifyCustomStyle.css";
import "./assets/css/ck_editor.css";
import "./assets/css/HyperPay.css";
import CircularCountDownTimer from "vue-circular-count-down-timer";
// import CKEditor from "@ckeditor/ckeditor5-vue";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
import VueBarcode from "vue-barcode";
import Echo from "laravel-echo";
Vue.prototype.moment = moment;
// eslint-disable-next-line no-unused-vars
import "./assets/js/plugins.js";
import "./assets/js/popper.min.js";
// import "./assets/js/bootstrap.min.js";
import "./assets/js/jquery.counterup.min.js";
import "./assets/js/moment.min.js";
// import "./assets/js/jquery.waypoints.min.js";
import "./assets/js/jquery.scrollUp.min.js";
import "./assets/js/printThis";
// import "./assets/js/fullcalendar.min.js";
// import "./assets/js/Chart.min.js";
if (window.location.pathname.split("/")[1] == "meeting") {
  require("./assets/zoom/bootstrap.css"); // bootstrap 3
  require("./assets/zoom/react.css");
}
/* import google map */
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyACgY2Kl1e1DAM80BgiwVUo8BKXzJ5tU7w",
    libraries: "places",
  },
});
Vue.use(DatetimePicker);
import "./assets/js/main.js";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.component("barcode", VueBarcode);
Vue.component("chat-line", {
  functional: true,
  // To compensate for the lack of an instance,
  // we are now provided a 2nd context argument.
  // https://vuejs.org/v2/guide/render-function.html#createElement-Arguments
  render: function (createElement, context) {
    const children = [];
    let lastMatchEnd = 0;
    var lineBreak = "\n",
      words = context.props.line.split(" "),
      newStr = words.shift(),
      charCount = newStr.length;

    words.forEach(function (word, i) {
      charCount += word.length + 1;
      if (charCount <= 90) {
        newStr += " ";
      } else {
        newStr += lineBreak;
        charCount = word.length;
      }
      newStr += word;
    });
    // demo only to show line lengths
    // var lines = newStr.split(lineBreak).map(function(str) {
    //   return str.length;
    // });
    // Todo, maybe use a better url regex, this one is made up from my head
    const urlRegex =
      /https?:\/\/([a-zA-Z0-9.-]+(?:\/[a-zA-Z0-9.%:_()+=-]*)*(?:\?[a-zA-Z0-9.%:_+&/()=-]*)?(?:#[a-zA-Z0-9.%:()_+=-]*)?)/g;
    const line = newStr;
    let match;
    while ((match = urlRegex.exec(line))) {
      if (match.index - lastMatchEnd > 0) {
        children.push(line.substring(lastMatchEnd, match.index));
      }
      children.push(
        createElement(
          "a",
          {
            attrs: {
              href: match[0],
            },
          },
          match[1]
        )
      ); // Using capture group 1 instead of 0 to demonstrate that we can alter the text
      lastMatchEnd = urlRegex.lastIndex;
    }
    if (lastMatchEnd < line.length) {
      // line.length - lastMatchEnd
      children.push(line.substring(lastMatchEnd, line.length));
    }
    // let p = createElement("p", { class: "chat-line" }, children);
    // console.log(children);
    // let text = children[0].split(" ");
    // text.array.forEach(element => {

    // });
    // console.log(children);
    // let text = children[0].split("\n");
    // console.log(text);
    // let arr = [];
    // text.forEach(element => {
    //   if (element.length > 100) {
    //     let start = 0;
    //     let end = 100;
    //     arr = element.substring(start, end);
    //   }
    // });
    var obj = createElement("p", { class: "chat-line" }, children);
    // obj.style.cssText = "word-break:break-all;";
    return obj;
  },
  // Props are optional
  props: {
    line: {
      required: true,
      type: String,
    },
  },
});
// Vue.forceUpdate();
Vue.use(CircularCountDownTimer);
// global mixins
import { mapGetters } from "vuex";
import axios from "axios";
Vue.prototype.$http = axios;

import { BreadCrumbMixin } from "@/mixins/BreadCrumbMixin";
Vue.mixin({
  mixins: [BreadCrumbMixin],
  data() {
    return {
      currentAppLocale:
        localStorage.locale === "ar" ||
        window.location.search.includes("?lang=ar")
          ? "ar"
          : "en",
    };
  },
  computed: {
    ...mapGetters([
      "getApiUrl",
      "getLandlordApiUrl",
      "getBackEndUrl",
      "getSideBarMin",
      "getSidebarDisplay",
    ]),
  },

  methods: {
    CheckArabicCharactersOnly(val) {
      if (val != null && val != undefined && val != "") {
        let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

        for (var i = 0; i < val.length; i++) {
          // console.log(val.charAt(i));
          var unicode = val.charCodeAt(i);
          var error = "";
          if (unicode != 8 && !format.test(val[i])) {
            //if the key isn't the backspace key (which we should allow)
            if (unicode == 32) error = true;
            else {
              if (
                (unicode < 48 || unicode > 57) &&
                (unicode < 0x0600 || unicode > 0x06ff)
              ) {
                //if not a number or arabic
                error = this.$i18n.t("Please write only arabic letters.");
                return error;
              } else {
                error = true; //disable key press
              }
            }
          }
        }
        return error;
      }
      return true;
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
      }
    },
  },
  mounted() {
    // setTimeout(function () {
    //   console.log("rendering mathjax");
    //   if (window.MathJax != undefined) {
    //     window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
    //       console.log("done")
    //     );
    //   }
    // }, 500);
  },
});

const socketUrl = process.env.VUE_APP_SOCKET_URL;
/* eslint-disable */
if (socketUrl != undefined) {

  const Pusher = require("pusher-js");
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_SOCKET_KEY,
    cluster: "mt1",
    wsHost: socketUrl,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    forceTLS: true,
  });
  Vue.prototype.$socket = window.Echo;
  console.log("%c socket is connecting => " + socketUrl + ' ', 'background: #919191; color: #fff;font-size:14px')
  window.Echo.connector.pusher.connection.bind('state_change', function (states) {
    if (states.current == 'connected') {
      console.log("%c socket is connected ", 'background: #006b1a; color: #fff;font-size:14px')
    } else {
      console.log("%c socket is " + states.current + ' ', 'background: #c50808; color: #fff;font-size:14px')
    }
    if (states.current === 'disconnected') {
      console.log('%c Socket Disconnected ', 'background: #c50808; color: #fff;font-size:14px');
      console.log('%c Trying to reconnect ', 'background: #919191; color: #fff;font-size:14px');
      window.Echo.connector.pusher.connect();
    }
  });
}


Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: localStorage.locale === "ar" || window.location.search.includes('?lang=ar') ? "ar" : "en",
  fallbackLocale: "en",
  messages: localization,
  silentTranslationWarn: true
});


// Ckeditor
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(CKEditor);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// v-can
Vue.directive("can", function (el, binding, vnode) {
  var permission = binding.value;
  if (!ACL.checkPermission(permission)) {
    el.remove();
  }
});

// v-has-menu
Vue.directive("hasMenu", function (el, binding, vnode) {
  var menu = binding.value;
  if (ACL.checkMenu(menu) == false) {
    el.remove();
  }
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if (error.response.status === 403) {
    //   window.location = "/403";
    // }
    if (error.response.status === 401) {
      localStorage.clear();
      if (window.location.pathname.includes("owner")) {
        window.location = "/owner/login";
      } else {
        window.location = "/login";
      }
    }
  }
);

// add locale using axios intereceptors to all requests
axios.interceptors.request.use(
  function (config) {
    config.headers["locale"] =
      localStorage.locale != undefined ? localStorage.locale : "en";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
