import Vue from "vue";
import VueRouter from "vue-router";

// import Home from "../views/Home.vue";

import ACL from "../acl";
Vue.use(VueRouter);

const routes = [
  {
    path: "/owner",
    name: "OwnerView",
    component: () => import("../views/landlord/Tenants.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (window.location.host.split(".").length <= 2) {
    //     next("/owner/login");
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/owner/login",
    name: "OwnerLoginView",
    component: () => import("../views/landlord/OwnerLoginView.vue"),
  },
  {
    path: "/login",
    name: "LoginView",
    component: () => import("../views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      if (window.location.host.split(".").length <= 2) {
        next("/owner/login");
      } else {
        next();
      }
    },
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../views/Users/ResetPassword.vue"),
  },
  {
    path: "/MasterData",
    name: "MasterData",
    component: () => import("../views/MasterData/masterDataRegistration.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Master Data")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/admission/:alias",
    name: "Admission",
    component: () => import("../views/Applicants/Admission.vue"),
  },
  {
    path: "/WarehouseTransaction",
    name: "WarehouseTransaction",
    component: () => import("../views/warehouses/AllTransaction.vue"),
  },
  {
    path: "/WarehouseTransactionItem/:item_id",
    name: "WarehouseTransactionItem",
    component: () =>
      import("../views/warehouses/WarehouseTransactionItemFilter.vue"),
  },
  {
    path: "/CreateWarehuseTransactions",
    name: "CreateWarehuseTransactions",
    component: () =>
      import("../views/warehouses/CreateWarehuseTransactions.vue"),
  },
  {
    path: "/warehouses_transactions/show/:id",
    name: "ShowWarehuseTransactions",
    component: () =>
      import("../views/warehouses/CreateWarehuseTransactions.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "All Transactions",
          disabled: false,
          href: "/WarehouseTransaction?page=1",
        },

        {
          text: "Transaction",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/ItemsList",
    name: "BooksList",
    component: () => import("../views/warehouses/StudentsBooksList.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/WarehouseItems",
    name: "WarehouseItems",
    component: () => import("../views/warehouses/WarehousesItems.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/ConfirmAdmissionExam/:examId/:applicant_code",
    name: "applicantViewOnlineExam",
    component: () =>
      import("../views/exams/admissionExam/applicantAdmissionExam.vue"),
  },
  {
    path: "/admissionExam/:examId/:applicant_code",
    name: "addmissionExam",
    component: () =>
      import("../views/exams/admissionExam/applicantAdmissionExamConfirm.vue"),
  },
  {
    path: "/ApplicantAnswer/:examId/:applicant_code",
    name: "ApplicantAnswer",
    component: () =>
      import("../views/exams/admissionExam/viewApplicantAnswer.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/finishExam",
    name: "FinishExam",
    component: () => import("../views/exams/admissionExam/finishPage.vue"),
  },
  {
    path: "/thankYou",
    name: "thankYou",
    component: () => import("../views/exams/admissionExam/finishPage.vue"),
  },
  {
    path: "/exam/success",
    name: "examSubmitted",
    component: () => import("../components/examSubmitted.vue"),
  },
  {
    path: "/applicantExamResults/:grade_year_id/:examId",
    name: "ApplicantExamResults",
    component: () =>
      import("../views/exams/admissionExam/applicantExamResults.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
    meta: {
      view: "applicantExamResults",
      breadcrumb: [
        {
          text: "Curriculum Setup",
          disabled: false,
          href: "/curriculum_setup/active",
        },
        {
          text: "Grade",
          disabled: false,
          href: "/gradeyears",
        },

        {
          text: "Applicant Exam Result",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/complete_profile/:applicant_code",
    name: "complete_profile",
    component: () => import("../views/Students/CompleteStudentProfile.vue"),
  },
  {
    path: "/uploadBankReceipts",
    name: "BankReceipts",
    component: () => import("../views/Payments/uploadPaymentCheck.vue"),
  },
  {
    path: "/completeStudentProfileEdit/:national_id",
    name: "CompleteStudentProfile",
  },
  {
    path: "/completeStudentProfileEdit/:national_id",
    name: "StudentProfile",
    component: () => import("../views/Students/CompleteStudentProfileEdit.vue"),
  },
  {
    path: "/students/:student_id",
    name: "ShowStudentProfile",
    component: () => import("../views/Students/ShowStudentProfile.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Students",
          disabled: false,
          href: "/students?page=1",
        },

        {
          text: "Student Application",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/admissionExam",
    name: "admissionExam",
    component: () => import("../views/Applicants/admissionExam.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Admission Exam")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/financialstatus",
    name: "financialstatus",
    component: () => import("../views/Payments/FinancialStatus.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Financial Status")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/digital_library",
    name: "digital_library",
    component: () => import("../views/DigitalLibrary/Library.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        // if (ACL.canAccessRoute("Digital Library")) {
        next();
        // } else {
        // next("/403");
        // }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: () => import("../views/Invoices/Invoices.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (
          ACL.canAccessRoute("view-invoices") ||
          ACL.canAccessRoute("edit-invoices") ||
          ACL.canAccessRoute("delete-invoices") ||
          ACL.canAccessRoute("print-invoices")
        ) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/discount_packages",
    name: "discount_packages",
    component: () => import("../views/Payments/DiscountPackges.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/subject_type",
    name: "SubjectType",
    component: () => import("../views/Subjects/SubjectType.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Subject Types")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/subjects",
    name: "Subject",
    component: () => import("../views/Subjects/Subject.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Subjects")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/applicants",
    name: "applicant",
    component: () => import("../views/Applicants/applicants.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("All Applicants")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/applicants/log/:applicant_id",
    name: "ApplicantLog",
    component: () => import("../views/Applicants/ApplicantLogs.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("show-applicants-logs")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/applicants/:applicant_id",
    name: "ApplicantsShow",
    component: () => import("../views/Applicants/ApplicantShow.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("show-applicants")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "All Applicants Data",
          disabled: false,
          href: "/applicants?page=1",
        },

        {
          text: "Applicant",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/academics",
    name: "AcademicPeriods",
    component: () => import("../views/MasterData/AcademicPeriods.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Academic Periods")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/curriculum_setup/:academicId",
    name: "CurriculumnSetup",
    component: () => import("../views/AcademicSetup/CurriculumSetup.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Curriculum Setup")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/itemsCategories",
    name: "ItemsCategories",
    component: () => import("../views/Items/ItemsCategories.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Items Categories")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/items",
    name: "Items",
    component: () => import("../views/Items/Items.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Items")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.canAccessRoute(true)) next();
      else next("/");
    },
  },
  {
    path: "/test-qr",
    name: "testqr",
    component: () => import("../views/testqr.vue"),
  },
  {
    path: "/grades",
    name: "grades",
    component: () => import("../views/Grades/Grade.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Grades")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/qualifier",
    name: "Qualifier",
    component: () => import("../views/Subjects/Qualifier.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Qualifier")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },

  {
    path: "/gradeyears/:grade_year_id",
    name: "GradeYearsRelatedData",
    component: () => import("../views/gradeYears/GradeYearsRelatedData.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (
          ACL.canAccessRoute("list-subjects-in-grade-year") ||
          ACL.canAccessRoute("list-academic-book-subjects") ||
          ACL.canAccessRoute("list-academic-class-subjects")
        ) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Curriculum Setup",
          disabled: false,
          href: "/curriculum_setup/active",
        },

        {
          text: "Grade",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/role_management",
    name: "role_management",
    component: () => import("../views/Users/RoleManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("User Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/user_management",
    name: "user_management",
    component: () => import("../views/Users/UserManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("User Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/all_users",
    name: "all_users",
    component: () => import("../views/Users/AllUsers.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Users")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/manage-Coordinators",
    name: "manage_coordinators",
    component: () => import("../views/Users/ManageCoordinators.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("manage-coordinators")) {
          next();
        } else {
          next("/403");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/login-as-teacher",
    name: "login-as-teacher",
    component: () => import("../views/Users/loginAsTeacher.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("login-as-teacher")) {
          next();
        } else {
          next("/403");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Users/userProfile.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        // if (ACL.canAccessRoute(true)) {
        next();
        // } else {
        //   next("/403");
        // }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/feeplans",
    name: "AllPlanFees",
    component: () => import("../views/planFees/AllPlanFees.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Fee Plans")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },

  {
    path: "/masterfees/:id",
    name: "MasterFees",
    component: () => import("../views/planFees/MasterFees.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("show-fee-plans")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Fee Plans",
          disabled: false,
          href: "/feeplans?page=1",
        },

        {
          text: "Fee Plan",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/taxes",
    name: "AllTax",
    component: () => import("../views/tax/AllTaxes.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Taxes")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/cashpoints",
    name: "CashPoints",
    component: () => import("../views/cashPoints/CashPoints.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Cash Points")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/payments/:id",
    name: "Payment",
    component: () => import("../views/Payments/cashCollectionVue.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("show-payments")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Payments",
          disabled: false,
          href: "/payments?tab=0&page=1",
        },

        {
          text: "Cash Collection",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/editPayments/:id",
    name: "EditPayment",
    component: () => import("../views/Payments/editPayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("edit-payments")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/paymentRedirect/:payment_id",
    name: "paymentRedirect",
    component: () => import("../views/Payments/paymentRedirect.vue"),
  },
  {
    path: "/PaymentRedirectMada/:payment_id",
    name: "PaymentRedirectMada",
    component: () => import("../views/Payments/PaymentRedirectMada.vue"),
  },
  {
    path: "/bankReceipts",
    name: "bankReceipts",
    component: () => import("../views/Payments/bankReceipts.vue"),
  },
  {
    path: "/bankReceiptsView/:id",
    name: "bankReceiptsView",
    component: () => import("../views/Payments/viewBankReceipt.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bank Receipts",
          disabled: false,
          href: "/bankReceipts?page=1",
        },

        {
          text: "View Bank receipt",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Partners/Partners.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Partners")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("../views/Home.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (ACL.isLoggedIn()) {
  //       // if (ACL.Role("Accountant")) {
  //       next();
  //       // } else {
  //       //   next("/403");
  //       // }
  //     } else {
  //       next("/login");
  //     }
  //   }
  // },
  {
    path: "/",
    name: "Dashboard",
    component() {
      if (
        ACL.Role("Super-Admin") ||
        ACL.Role("Coordinator") ||
        ACL.Role("Admin")
      ) {
        return import("../views/Dashboard/AdminDashboard.vue");
      } else if (ACL.Role("Parent")) {
        return import("../views/Dashboard/ParentDashboard.vue");
      } else if (ACL.Role("Teacher")) {
        return import("../views/Dashboard/secondTeacherDashboard.vue");
      } else if (ACL.Role("Student")) {
        return import("../views/Dashboard/StudentDashboard.vue");
      } else if (ACL.Role("HR-Admin")) {
        return import("../views/Dashboard/HrDashboard.vue");
      } else {
        return import("../views/Home.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (window.location.host.split(".").length <= 2) {
        next("/owner/login");
      } else {
        if (ACL.isLoggedIn()) {
          next();
        } else {
          next("/login");
        }
      }
    },
  },
  // {
  //   path: "/dashboard",
  //   name: "ParentDashboard",
  //   component: () => import("../views/Dashboard/ParentDashboard.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (ACL.isLoggedIn()) {
  //       if (ACL.Role("Parent") && ACL.canAccessRoute("Dashboard")) {
  //         next();
  //       } else {
  //         next("/403");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   }
  // },
  // {
  //   path: "/dashboard",
  //   name: "TeacherDashboard",
  //   component: () => import("../views/Dashboard/TeacherDashboard.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (ACL.isLoggedIn()) {
  //       if (ACL.Role("Teacher") && ACL.canAccessRoute("Dashboard")) {
  //         next();
  //       } else {
  //         next("/403");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   }
  // },
  // {
  //   path: "/dashboard",
  //   name: "StudentDashboard",
  //   component: () => import("../views/Dashboard/StudentDashboard.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (ACL.isLoggedIn()) {
  //       if (ACL.Role("Student") && ACL.canAccessRoute("Dashboard")) {
  //         next();
  //       } else {
  //         next("/403");
  //       }
  //     } else {
  //       next("/");
  //     }
  //   }
  // },
  {
    path: "/students",
    name: "Students",
    component: () => import("../views/Students/Students.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Students") && ACL.canAccessRoute("Dashboard")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/cloneAcademicPeriods/:id",
    name: "cloneAcademicPeriods",
    component: () => import("../views/MasterData/CloneAcademicPeriods.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/classmanagement/:academicId",
    name: "ClassManagement",
    component: () => import("../views/Classes/ClassManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/manageclass/:academicYear/:classId/",
    name: "ManageClass",
    component: () => import("../views/Classes/manageClass.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Class Management",
          disabled: false,
          href: "/classmanagement/all",
        },

        {
          text: "Class ",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/studentsexamresults/:academicYear/:classId/:examId",
    name: "StudentExamResults",
    component: () => import("../views/exams/StudentsExamResults.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
    meta: {
      view: "StudentsExamResults",
      breadcrumb: [
        {
          text: "Class Management",
          disabled: false,
          href: "/classmanagement/all",
        },
        {
          text: "Exams",
          disabled: false,
          href: "/manageclass/",
        },
        {
          text: "Students Exam Results",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/classexamreport/:academicYear/:classId/:examId",
    name: "ClassExamReport",
    component: () => import("../views/exams/classExamReport.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
    meta: {
      view: "classexamreport",
      breadcrumb: [
        {
          text: "Class Management",
          disabled: false,
          href: "/classmanagement/all",
        },
        {
          text: "Exams",
          disabled: false,
          href: "/manageclass/1/1?tab=3&page=1",
        },
        {
          text: "Students Exam Results",
          disabled: false,
          href: "/studentExamResult/",
        },
        {
          text: "Class Exam Report",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/createOnlineExam/:examId",
    name: "createOnlineExam",
    component: () => import("../views/exams/createOnlineExam.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/viewStudentJoined/:academicYear/:classId/:examId",
    name: "viewStudentJoined",
    component: () => import("../views/exams/viewStudentJoined.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
    meta: {
      view: "viewStudentJoined",
      breadcrumb: [
        {
          text: "Class Management",
          disabled: false,
          href: "/classmanagement/all",
        },
        {
          text: "Exams",
          disabled: false,
          href: "/manageclass/",
        },

        {
          text: "Student Joined exam",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/examTimer/:examId",
    name: "examTimer",
    component: () => import("../views/exams/examTimer.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/attendance/",
    name: "classAttendance",
    component: () => import("../views/classRooms/attendance.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Teacher")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/leavetime/",
    name: "leaveTime",
    component: () => import("../views/nedaa/leaveTime.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Admin")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/nedaa/settings/",
    name: "settings",
    component: () => import("../views/nedaa/settings.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Admin")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/nedaa/child/call",
    name: "nedaa-child-call",
    component: () => import("../views/nedaa/parent/childCall.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Parent") || ACL.Role("Driver")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/nedaa/callLinks/",
    name: "callLinks",
    component: () => import("../views/nedaa/callLinks.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Admin")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/nidaa/allcalls",
    name: "allcalls",
    component: () => import("../views/nedaa/allcalls.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/nedaa/:id",
    name: "linkVerification",
    component: () => import("../views/nedaa/linkVerification.vue"),
  },
  {
    path: "/checkout",
    name: "studentCheckout",
    component: () => import("../views/nedaa/studentCheckout.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/studentsQrCode",
    name: "studentsQrCode",
    component: () => import("../views/nedaa/studentsQrCode.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Admin")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/drivers",
    name: "drivers",
    component: () => import("../views/nedaa/drivers.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Admin")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/callHistory",
    name: "callHistory",
    component: () => import("../views/nedaa/callHistory.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Super-Admin") || ACL.Role("Admin")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/studentAttendance/",
    name: "StudentAttendance",
    component: () => import("../views/Students/StudentAttendance.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Student")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/attendance/students",
    name: "AllClassAttendance",
    component: () => import("../views/classRooms/all_attendance.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (
          ACL.Role("Super-Admin") ||
          ACL.Role("Admin") ||
          ACL.Role("Coordinator") ||
          ACL.Role("CR-Represnetative")
        ) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/attendanceChild",
    name: "AllChildsAttendance",
    component: () => import("../views/classRooms/attendanceViewParent.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Parent")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/viewOnlineExam/:examId",
    name: "viewOnlineExam",
    component: () => import("../views/exams/viewOnlineExam.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/studentViewOnlineExam/:examId",
    name: "studentViewOnlineExam",
    component: () => import("../views/exams/studentOnlineExamView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/examCorrection/:academicYear/:classId/:examId/:studentId",
    name: "examCorrection",
    component: () => import("../views/exams/examCorrection.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      view: "examCorrection",
      breadcrumb: [
        {
          text: "Class Management",
          disabled: false,
          href: "/classmanagement/all",
        },
        {
          text: "Exams",
          disabled: false,
          href: "/manageclass/",
        },
        {
          text: "Students Exam Results",
          disabled: false,
          href: "/studentExamResult/",
        },
        {
          text: "Exam Correction",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/teachers",
    name: "teachers",
    component: () => import("../views/Teachers/Teachers.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Teachers")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/myschedule",
    name: "myschedule",
    component: () => import("../views/Teachers/mySchedule.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.Role("Teacher")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/teachers/schedule/:teacherId",
    name: "teachers.schedule",
    component: () => import("../views/Teachers/TeacherSchedule.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Teachers")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Teachers",
          disabled: false,
          href: "/teachers?page=1",
        },

        {
          text: "Schedule",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/workingdays",
    name: "WorkingDays",
    component: () => import("../views/schoolSetup/WorkingDaysConfig.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        console.log(ACL.canAccessRoute("Configrations"));
        if (ACL.canAccessRoute("Configrations")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/gradingsystem",
    name: "GradingSystem",
    component: () => import("../views/schoolSetup/GradingSystem.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/invoices/:invoiceId",
    name: "Invoice",
    component: () => import("../views/Payments/invoice.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Invoices",
          disabled: false,
          href: "/invoices?page=1",
        },

        {
          text: "Invoice",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/HyperPayMada/:code",
    name: "HyperPayMada",
    component: () => import("../views/Payments/HyperPayMada.vue"),
  },
  {
    path: "/HyperPayChooseBrand/:code",
    name: "HyperPayChooseBrand",
    component: () => import("../views/Payments/HyperPayChooseBrand.vue"),
  },
  {
    path: "/HyperPay/:code",
    name: "HyperPay",
    component: () => import("../views/Payments/HyperPay.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    component: () => import("../views/Payments/payments.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Payments")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/payments/refund/:transactionId",
    name: "PaymentsRefund",
    component: () => import("../views/Payments/refundPayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("refund-payment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/cashcollection/:invoiceId",
    name: "cashCollection",
    component: () => import("../views/Payments/cashCollection.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("create-payment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },
  {
    path: "/generalPayment",
    name: "generalPayment",
    component: () =>
      import("../views/Payments/GeneralPayments/CreateGeneralPayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      }
    },
  },
  {
    path: "/generalPayment/:id",
    name: "ViewGeneralPayment",
    component: () =>
      import("../views/Payments/GeneralPayments/ViewGeneralPayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Payments",
          disabled: false,
          href: "/payments?tab=1",
        },

        {
          text: "Transaction",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/reversegeneralPayment/:id",
    name: "ReverseGeneralPayment",
    component: () =>
      import("../views/Payments/GeneralPayments/ReverseGeneralPayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      }
    },
  },
  {
    path: "/viewReverseGeneralPayment/:id",
    name: "ViewReverseGeneralPayment",
    component: () =>
      import("../views/Payments/GeneralPayments/ViewReverse.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      }
    },
  },
  {
    path: "/editGeneralPayment/:id",
    name: "EditGeneralPayment",
    component: () =>
      import("../views/Payments/GeneralPayments/UpdateGeneralPayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      }
    },
  },
  {
    path: "/meeting/:meetingId",
    name: "Meeting",
    component: () => import("../views/zoomMeeting/meeting.vue"),
  },
  {
    path: "/zoomiframe/:meetingId",
    name: "zoomIframe",
    props: true,
    component: () => import("../views/zoomMeeting/zoomIframe.vue"),
  },
  {
    path: "/discussionboard/:roomId",
    name: "discussionBoard",
    props: true,
    component: () => import("../views/discussionBoard/discussionBoard.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Discussion Rooms",
          disabled: false,
          href: "/discussionrooms?page=1",
        },

        {
          text: "Room",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/homework",
    name: "homework",
    props: true,
    component: () => import("../views/homework/listHomeWork.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/homework/:homeworkId",
    name: "CreateHomeWork",
    props: true,
    component: () => import("../views/homework/CreateHomeWork.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/studentshomeworkresults/:homeWorkId",
    name: "StudentsHomeWorkresults",
    component: () => import("../views/homework/StudentsHomeWorkResults.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Assignments",
          disabled: false,
          href: "/homework",
        },

        {
          text: "Students Results",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/viewHomeWork/:homeWorkId/:studentId",
    name: "viewHomeWork",
    component: () => import("../views/homework/viewHomeWork.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (ACL.canAccessRoute("Class Managment")) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Assigments",
          disabled: false,
          href: "/homework",
        },

        {
          text: "View HomeWork",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/StudentHomeWorkDetails/:homeWorkId",
    name: "StudentHomeWorkDetails",
    component: () => import("../views/homework/StudentHomeWorkDetails.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        // if (ACL.canAccessRoute("Class Managment")) {
        next();
        // } else {
        // next("/");
        // }
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/StudentHomeWorkView/:homeWorkId",
    name: "StudentHomeWorkView",
    component: () => import("../views/homework/StudentHomeWorkView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/questionsubmit/:homeWorkId/:questionId",
    name: "questionSubmit",
    component: () => import("../views/homework/questionSubmit.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/discussionrequests/:roomId",
    name: "discussion Requests",
    props: true,
    component: () => import("../views/discussionBoard/discussionRequests.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/discussionrequests/editPost/:roomId/:postId",
    name: "discussion edit post",
    props: true,
    component: () => import("../views/discussionBoard/PostEdit.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/discussioncommentrequests/:roomId",
    name: "discussion comments Requests",
    props: true,
    component: () =>
      import("../views/discussionBoard/discussionCommentsRequests.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/discussionrequests/postApproval/:roomId/:postId",
    name: "discussion Requests Post Approval",
    props: true,
    component: () => import("../views/discussionBoard/PostApproval.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/discussionrooms",
    name: "discussionRooms",
    props: true,
    component: () => import("../views/discussionBoard/discussionRoom.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/ZoomLinks",
    name: "ZoomLinks",
    props: true,
    component: () => import("../views/classRooms/ZoomLinks.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/allAccounts/types",
    name: "acountsTypes",
    props: true,
    component: () => import("../views/Accounts/AccountType.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/accounts",
    name: "Accounts",
    props: true,
    component: () => import("../views/Accounts/Accounts.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/inbox",
    name: "Inbox",
    props: true,
    component: () => import("../views/Inbox/allinbox.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/inbox/create",
    name: "Inbox/create",
    props: true,
    component: () => import("../views/Inbox/createInbox.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/inbox/show/:id",
    name: "inboxShow",
    props: true,
    component: () => import("../views/Inbox/inboxShow.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/notificationsLogs",
    name: "notificationsLogs",
    props: true,
    component: () => import("../views/Notifications/NotificationsLogs.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/notificationTemplates",
    name: "notificationTemplates",
    props: true,
    component: () => import("../views/Notifications/NotificationTemplates.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/notificationTemplates/:id",
    name: "EditNoticationTemplate",
    props: true,
    component: () =>
      import("../views/Notifications/EditNoticationTemplate.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Notification Events",
          disabled: false,
          href: "/notificationTemplates?page=1",
        },

        {
          text: "Notification Template",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/createPushNotification",
    name: "CreatePushNotification",
    props: true,
    component: () =>
      import(
        "../views/Notifications/PushNotifications/CreatePushNotification.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/create_mail_sms",
    name: "sendCustomEmailOrSms",
    props: true,
    component: () => import("../views/Notifications/sendCustomEmailOrSms.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/hrConfigrations",
    name: "hrConfigrations",
    props: true,
    component: () => import("../views/HrModule/hrConfigrations.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/employees",
    name: "employees",
    props: true,
    component: () => import("../views/HrModule/Allemployees.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/employees/create",
    name: "CreateEmployees",
    props: true,
    component: () => import("../views/HrModule/Employee/createEmployees.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/employees/show/:id",
    name: "ShowEmployees",
    props: true,
    component: () => import("../views/HrModule/Employee/ShowEmployees.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      breadcrumb: [
        {
          text: "Employees Profiles",
          disabled: false,
          href: "/employees?page=1",
        },

        {
          text: "Employee",
          disabled: true,
          href: "#",
        },
      ],
    },
  },
  {
    path: "/allEmployees/salaries",
    name: "EmployeesSalaries",
    props: true,
    component: () => import("../views/HrModule/Employee/salaries.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/viewPushNotification/:id",
    name: "ViewPushNotification",
    props: true,
    component: () =>
      import(
        "../views/Notifications/PushNotifications/ViewPushNotification.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/editPushNotification/:id",
    name: "EditPushNotification",
    props: true,
    component: () =>
      import(
        "../views/Notifications/PushNotifications/EditPushNotification.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/educationalhistory/teacherview",
    name: "teacherView",
    component: () => import("../views/EducationalHistory/teacherView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (!ACL.Role("Teacher")) {
          next("/403");
        }
        next();
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/AdminTeacherView",
    name: "AdminTeacherView",
    component: () => import("../views/EducationalHistory/AdminTeacherView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        if (!ACL.Role("Super-Admin")) {
          next("/403");
        }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/studentView",
    name: "studentView",
    component: () => import("../views/EducationalHistory/studentView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        if (ACL.Role("Student")) {
        } else {
          next("/403");
        }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/parentView",
    name: "parentView",
    component: () => import("../views/EducationalHistory/parentView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        if (ACL.Role("Parent")) {
        } else {
          next("/403");
        }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/adminView",
    name: "adminView",
    component: () => import("../views/EducationalHistory/adminView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        if (
          ACL.Role("Super-Admin") ||
          ACL.Role("Coordinator") ||
          ACL.Role("Admin")
        ) {
        } else {
          next("/403");
        }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/subjectView",
    name: "subjectView",
    component: () => import("../views/EducationalHistory/subjectView.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/subjectDetails",
    name: "subjectDetails",
    component: () => import("../views/EducationalHistory/subjectDetails.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/educationalhistory/qualifiersmarks",
    name: "qualifiersMarks",
    component: () => import("../views/EducationalHistory/qualifiersMarks.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/systemlogging/:viewLog",
    name: "logging",
    component: () => import("../views/systemLog/logging.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/AccountStatements",
    name: "AccountStatement",
    component: () => import("../views/AccountStatement/AccountStatement.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/AccountStatements/:code",
    name: "AccountStatementDetails",
    component: () =>
      import("../views/AccountStatement/AccountStatementDetails.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/AccountStatements/:code/createPayment",
    name: "AccountStatementCreatePayment",
    component: () =>
      import("../views/AccountStatement/AccountStatementCreatePayment.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        next();
        // if (ACL.Role("Teacher")) {
        // } else {
        //   next("/403");
        // }
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/invoiceupdate",
    name: "invoiceupdate",
    component: () => import("../views/AccountStatement/Invoices.vue"),
    beforeEnter: (to, from, next) => {
      if (ACL.isLoggedIn()) {
        if (
          ACL.canAccessRoute("view-invoices") ||
          ACL.canAccessRoute("edit-invoices") ||
          ACL.canAccessRoute("delete-invoices") ||
          ACL.canAccessRoute("print-invoices")
        ) {
          next();
        } else {
          next("/");
        }
      } else {
        next("/");
      }
    },
  },

  // {
  //   path: "/meeting_rules/:meetingId",
  //   name: "Meeting Rules",
  //   component: MeetingRules
  // },
  // {
  //   path: "/thank-you/:id",
  //   name: "Thank You",
  //   component: ThankYou
  // },
  // {
  //   path: "/thank-you-t/:id",
  //   name: "Thank You",
  //   component: ThankYouTeacher
  // },
  {
    path: "/403",
    name: "Component403",
    component: () => import("../components/Component403.vue"),
  },
  // {
  //   path: "/hrdashboard",
  //   name: "HrDashboard",
  //   component: () => import("../views/Dashboard/HrDashboard.vue")
  // },
  {
    path: "/teacherdashboard",
    name: "secondTeacherDashboard",
    component: () => import("../views/Dashboard/secondTeacherDashboard.vue"),
  },
  {
    path: "/reviewrequest",
    name: "ReviewRequest",
    component: () => import("../views/Dashboard/ReviewRequest.vue"),
  },
  {
    path: "/MasterAttendance",
    name: "MasterAttendance",
    component: () => import("../views/HrModule/MasterAttendance.vue"),
  },
  {
    path: "/LeaveRequest",
    name: "LeaveRequest",
    component: () => import("../views/HrModule/Employee/LeaveRequest.vue"),
  },
  {
    path: "/VacationRequests",
    name: "VacationRequests",
    component: () => import("../views/HrModule/Employee/VacationRequests.vue"),
  },
  {
    path: "/test-editor",
    name: "TestEditor",
    component: () => import("../views/test.vue"),
  },
  {
    path: "*",
    name: "Component404",
    component: () => import("../components/Component404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
