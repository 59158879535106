<template>
  <div id="navbar">
    <v-card color="grey lighten-4" flat tile>
      <v-toolbar class="toolBar" dense height="65px">
        <!-- <v-app-bar-nav-icon @click="showSideBar"></v-app-bar-nav-icon> -->

        <v-toolbar-title>
          <v-img :src="logoPath" style="max-width: 150px" />
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="nav-icon" id="notification_icon">
              notifications
            </v-icon>
          </template>
          <v-list id="notification">
            <v-list-item v-for="(item, index) in notifications" :key="index">
              <v-list-item-title class="notification-item" :class="item.class">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>-->
        <!-- notification -->

        <!-- <label style="margin: 10px; color: #7296fb"
          ><a @click="ChangeLanguage">{{
            currentAppLocale == "en" || currentAppLocale == undefined
              ? $t("AR")
              : $t("EN")
          }}</a></label
        > -->
        <!-- <span class="ml-2"> | </span> -->
        <!-- <p style="margin-left: 10px; margin-top: 30px" class="username"> -->
        <!-- <span>
            {{ "iSkola Owner" }}
          </span> -->
        <!-- 
          <span style="display: block; text-align: left">
            {{ "Super Admin" }}
          </span> -->
        <!-- </p> -->
        <span>
          <img
            id="logo"
            src="../assets/avatar.png"
            style="width: 40px; height: 40px; border-radius: 50%"
          />
        </span>
        <v-menu offset-y style="z-index: 1111 !important; width: 200px">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="nav-icon"
              id="account_circle_icon"
              style="color: #444"
              >arrow_drop_down</v-icon
            >
          </template>

          <v-list id="accountMenu">
            <div v-for="(item, index) in accountitems" :key="index">
              <v-list-item link :to="item.path" v-if="item.enabled">
                <v-list-item-title
                  class="notification-item"
                  :class="item.class"
                  @click="
                    if (item.method == 'logout') logout();
                    else if (item.method == 'switchRole') switchRole();
                    else hide();
                  "
                >
                  <v-icon>{{ item.icon }}</v-icon>
                  <label
                    v-if="
                      item.title != 'last Login :' &&
                      item.title != 'اخر تسجيل للدخول :'
                    "
                    >{{ item.title }}</label
                  >
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-card>

    <!-- user update data modal -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoPath: require("../assets/Iskola_logo.png"),
      currentAppLocale: localStorage.locale,
      accountitems: [
        // {
        //   title: this.$i18n.t("My Profile"),
        //   icon: "person",
        //   method: "",
        //   class: "",
        //   path: "/profile",
        //   enabled: true,
        // },

        {
          title: this.$i18n.t("Logout"),
          icon: "check_circle",
          method: "logout",
          class: "",
          path: "",
          enabled: true,
        },
      ],
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location = "/owner/login";
    },
    ChangeLanguage() {
      //
    },
  },
};
</script>

<style scoped>
#navbar header {
  padding: 0 25px !important;
}
</style>
