import moment from "moment";
export const validationMixin = {
  data() {
    return {
      // vuetify validation
      validationRules: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
        required_multiple: (v) =>
          !v.length == 0 || this.$i18n.t("This field is required"),
        year: (v) => {
          if (v) {
            return (
              v.length == 2 ||
              v.length == 4 ||
              this.$i18n.t("should be a two-digit or four-digit year")
            );
          } else return true;
        },
        month: (v) => {
          if (v) {
            return v <= 12 || this.$i18n.t("Please enter valid month");
          } else return true;
        },
        positiveFloatNumber: (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) > 0 && /^\d+(\.\d{1,2})?$/.test(v)) ||
              this.$i18n.t("Please enter valid number")
            );
          } else return true;
        },
        positiveFloatNumberWithOneDecimal: (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) > 0 && /^\d+(\.\d{1,1})?$/.test(v)) ||
              this.$i18n.t("Please enter valid number")
            );
          } else return true;
        },
        positiveFloatNumberNozro: (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) > 0) ||
              this.$i18n.t("Please enter valid number")
            );
          } else return true;
        },
        // imageValidation: v =>
        //   v.size < 2000000 || "Avatar size should be less than 2 MB!",
        // minOne: v => v >= 1 || this.$i18n.t("minOneValidation"),
        maxLength2: (v) => {
          if (v) {
            return (
              v.length <= 2 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        lessThanOrEqual: (v, number) => {
          if (v) {
            return v <= number || this.$i18n.t("cannot exceed ") + number + "%";
          } else return true;
        },
        maxLength4: (v) => {
          if (v) {
            return (
              v.length <= 4 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength5: (v) => {
          if (v) {
            return (
              v.length <= 5 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength10: (v) => {
          if (v) {
            return (
              v.toString().length <= 10 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength13: (v) => {
          if (v) {
            return (
              v.length <= 13 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },

        maxLength50: (v) => {
          if (v) {
            return (
              v.length <= 50 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength100: (v) => {
          if (v) {
            return (
              v.length <= 100 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength150: (v) => {
          if (v) {
            return (
              v.length <= 150 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength400: (v) => {
          if (v) {
            return (
              v.length <= 400 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength1000: (v) => {
          if (v) {
            return (
              v.length <= 1000 ||
              this.$i18n.t("you have reached max allowed characters")
            );
          } else return true;
        },
        maxLength25: (v) => {
          if (v)
            return (
              v.length <= 25 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        maxLength20: (v) => {
          if (v)
            return (
              v.length <= 20 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        maxLength225: (v) => {
          if (v)
            return (
              v.length <= 225 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        maxLength6: (v) => {
          if (v)
            return (
              (v && v.length <= 6) ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        floatNumber: (v) => {
          if (v)
            return (
              (v && /^\d*\.?(?:\d{1,2})?$/.test(v)) ||
              this.$i18n.t("This field only accept positive numbers")
            );
          else return true;
        },
        floatNumberNotZero: (v) => {
          if (v)
            return (
              (v && /^\d*\.?(?:\d{1,2})?$/.test(v) && v > 0) ||
              this.$i18n.t("This field only accept positive numbers")
            );
          else return true;
        },
        numeric: (v) => {
          if (v)
            return (
              (v && /^\d+$/.test(v)) ||
              this.$i18n.t("This field only accept positive numbers")
            );
          else return true;
        },
        numericNotZero: (v) => {
          if (v)
            return (
              (v && /^\d+$/.test(v) && v > 0) ||
              this.$i18n.t("This field only accept numbers large than zero")
            );
          else return true;
        },
        percentage: (v) =>
          (v > 0 && v <= 100) ||
          this.$i18n.t("The percentage must be between 0 and 100."),
        alphaNum: (v) =>
          (v && /^\w+$/.test(v)) || this.$i18n.t("Please enter a valid format"),
        // /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,10}$/.test(v)
        email: (v) =>
          /.+@.+\..+/.test(v) || this.$i18n.t("Please enter a valid format"),
        phoneNum: (v) =>
          /^(\+?)[0-9]+$/.test(v) ||
          this.$i18n.t("Please enter a valid format"),
        notStartWithZero: (v) =>
          (v.length == 9 && v[0] != 0) ||
          this.$i18n.t(
            "This field must be only 9 numbers and not start with 0"
          ),
        spaces: (v) =>
          /^(.*[^ ].*)/.test(v) ||
          this.$i18n.t("This field shouldn't contain spaces only"),
      },
    };
  },

  methods: {
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate();
    },
    checkMultiSelect(selectArray) {
      return selectArray && selectArray.length == 0
        ? this.$i18n.t("This field is required")
        : true;
    },
    checkboxValidation(checkboxArray) {
      return checkboxArray.length == 0
        ? this.$i18n.t("This field is required")
        : true;
    },

    mobileRule(country_code, mobile) {
      if (country_code == 187) {
        return !/^((?!0)[0-9]{9})*$/.test(mobile)
          ? this.$i18n.t(
            "This field must be only 9 numbers and not start with 0"
          )
          : mobile.length <= 5 || mobile.length >= 15
            ? this.$i18n.t(
              "This field must be only 9 numbers and not start with 0"
            )
            : true;
      } else {
        if (mobile != null) {
          return !/^([0-9])*$/.test(mobile)
            ? this.$i18n.t(
              "Mobile Number must be between 5 to 15 digits. first"
            )
            : mobile.length <= 5 || mobile.length >= 15
              ? this.$i18n.t(
                "Mobile Number must be between 5 to 15 digits. second"
              )
              : true;
        } else {
          return this.$i18n.t("Mobile Number must be between 5 to 15 digits.");
        }
      }
    },
    positiveNumber(val) {
      if (Number(val) && val < 0) {
        return this.$i18n.t("Only positive numbers allowed");
      }
      return true;
    },

    startDateRule(start, end, str) {
      if (start && end) {
        var startDate = moment(start, "DD/MM/YYYY");
        var endDate = moment(end, "DD/MM/YYYY");
        console.log(startDate, endDate);
        return startDate >= endDate ? str : true;
      }
    },
    startDateOrEqualRule(start, end, str) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate > endDate ? str : true;
    },
    startTimeRule(start, end, str) {
      var startTime = moment(start, "HH:mm");
      var endTime = moment(end, "HH:mm");
      return startTime >= endTime ? str : true;
      // console.log("error");
    },
    startDateTimeOrEqualRule(start, end, str) {
      start = Date.parse(start);
      end = Date.parse(end);

      if (start && end) {
        if (start >= end) {
          this.startDateError = str;
          this.endDateError = str;
        } else {
          this.startDateError = "";
          this.endDateError = "";
          return true;
        }
      }
    },

    fromToValidation(start, end, str) {
      if (start && end) {
        start = Date.parse(start);
        end = Date.parse(end);

        if (start >= end) {
          return str;
        } else {
          return true;
        }
      }

      return true;
    },
    fromToValidationًWithStatrEqual(start, end, str) {
      if (start && end) {
        start = Date.parse(start);
        end = Date.parse(end);

        if (start > end) {
          return str;
        } else {
          return true;
        }
      }

      return true;
    },
  },
};
